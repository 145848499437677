import React from 'react'
import './lookBetter.scss'
import { images } from '../../../../actions/customFn'

export default function LookBetter() {
    return (
        <div className='section'>
            <div className='container'>
                <div className='look-section'>
                    <div className='grid-section'>
                        <div className='item1'>
                            <div className='left-section bg_image_cls' style={{backgroundImage: `url(${images["look-bg.png"]})`}}>
                                <div>
                                <h2 className='common_heading_cls'>We made your everyday
                                    Look better!</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                                </p>
                                <button type='button' className='common_button_cls'>Shop Now</button>
                                </div>
                            </div>
                        </div>
                        <div className='item2'>
                            <img src={images["selfi.png"]} className='selfi'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import './subscrib.scss'
import { ArrowBack } from '@mui/icons-material'
export default function Subscribe() {
  return (
    <div>
      <div className='subscribe-section'>
        <div className='content'>
          <h2 className='section_heading'>Subscribe to our newsletter</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text </p>
            <div className='sub-input'>
              <input type='text' placeholder='Email address'/>
              <button type='button' className='square-btn'>Subscribe<ArrowBack/></button>
            </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import './footer.scss'
import { images } from '../../../../actions/customFn'
import { Link } from 'react-router-dom'
import { FacebookIcons, InstaIcon, TwitterIcon, LinkedInIcon, PhoneIcon, LocationIcon, TwitterGolden, LinkedInFilled, FbGolden, InstaGolden, LinkedInGolden } from '../../../../icons/incons'

export default function Footer() {
    return (
            <div className='footer'>
                <div className='container '>
                    <div className='row upper-section'>
                        <div className='col-md-4 col-12'>
                            <div className='site-content'>
                                <img src={images["logo-blue.svg"]} />
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                </p>
                                <ul className='icons lists_cls'>
                                    <li><Link to=""><FbGolden /></Link></li>
                                    <li ><Link to="" className='change-stroke'><InstaGolden /></Link></li>
                                    <li><Link to="" className='change-both'><TwitterGolden /></Link></li>
                                    <li><Link to=""><LinkedInFilled /></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-2 col-6'>
                            <div className='footer-links'>
                                <h2 className='common_heading_cls'>Navigation</h2>
                                <ul className='lists_cls'>
                                    <li><Link to="">Home</Link></li>
                                    <li><Link to="/about-us">About Us</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/terms-conditions">Terms & Condition</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-2 col-6'>
                            <div className='footer-links'>
                                <h2 className='common_heading_cls'>Links</h2>
                                <ul className='lists_cls light-link'>
                                    <li><Link to="">Orders</Link></li>
                                    <li><Link to="">Wishlist</Link></li>
                                    <li><Link to="/contact-us">Contact us</Link></li>
                                    <li><Link to="">Xelariz Credit</Link></li>
                                    <li><Link to="">Return & Exchange</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='footer-links'>
                                <h2 className='common_heading_cls'>Contact Us</h2>
                                <ul className='lists_cls light-link address'>
                                    <li>Our Support and Sales team is available 24 /7 to answer your queries</li>
                                    <li><PhoneIcon />123 Main St, Suite 500, New York, NY 10001</li>
                                    <li><LocationIcon />+1 (333) 000-0000</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-top'>
                    <div className='container'>
                        <div className='copy-right'>
                            <div className='copy-content'>
                                <div><span>Copyrigt © 2024 Xelariz</span></div>
                                <div>
                                    <span><Link to="./terms-conditions">Terms of Use</Link></span>
                                    <span>{" "}|{" "}</span>
                                    <span><Link to="">Privacy Policy</Link></span>
                                </div>
                            </div>
                            <ul className='icons lists_cls'>
                                <li><Link to="" ><FbGolden /></Link></li>
                                <li><Link to="" ><InstaGolden /></Link></li>
                                <li><Link to=""><TwitterGolden /></Link></li>
                                <li><Link to=""><LinkedInGolden /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    )
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './tabs.scss'
import DeliveryDetails from './delivery';

export default function InfoTabs() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='tabs_section'>
        <div className='container'>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider',justifyContent:"center" }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" className='tabs_data'>
                            <Tab label="Delivery details" value="1" className='tab_button'/>
                            <Tab label="Technical information" value="2" className='tab_button'/>
                            <Tab label="Review" value="3" className='tab_button'    />
                        </TabList>
                    </Box>
                    <TabPanel value="1" className='tabs_value'><DeliveryDetails/></TabPanel>
                    <TabPanel value="2" className='tabs_value'><DeliveryDetails/></TabPanel>
                    <TabPanel value="3" className='tabs_value'><DeliveryDetails/></TabPanel>
                </TabContext>
            </Box>
        </div>
        </div>
    );
}

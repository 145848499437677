import React from 'react'
import './products.scss'
import { images } from '../../../../actions/customFn';
import { Heart } from '../../../../icons/incons';
import { Link } from 'react-router-dom';

const ProductsCards = [
    {
        lable: 'New',
        img: 'specks1.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: 'New',
        img: 'specks2.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: '',
        img: 'specks3.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: '',
        img: 'specks4.png',
        details: 'Vincent case Polarized'
    }
]
export default function Seller() {
    return (
        <div className='section'>
            <div className='explore-products'>
                <div className="seller" >
                    <div className='container'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p className='text_golden_cls'>Best glasses</p>
                                <h2 className='common_heading_cls'>Our Best seller</h2>
                            </div>
                            <Link to="/products" className='view-link'>Viwe All</Link>
                        </div>
                        <div className='slide-items'>
                            <div className='grid-section'>
                                {ProductsCards.map((item, index) => (
                                    <div className=''>
                                        <Link to="/products" key={index}>
                                            <div className='product-cards shine' >
                                                <div className='card-upper'>
                                                    {item.lable &&
                                                        <div className='label'>
                                                            <span>New</span>
                                                        </div>
                                                    }
                                                    <button className='circle-btn'>
                                                        <Heart />
                                                    </button>
                                                    <img src={images[`${item.img}`]} alt="User" />
                                                </div>
                                                <div className='card-lower'>
                                                    <p>{item.details}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { HomeIcon } from '../../../../icons/incons'
import { Link } from 'react-router-dom'
import './navpath.scss'

export default function NavPath({ activeTab }) {
  return (
    <div className='navpath'>
      <div className='container'>
        <ul className='lists_cls'>
          <li ><HomeIcon /> <Link to="/" className='text_14'>Home  {">"}</Link></li>
          <li><Link to="" className='text_14'>{activeTab}</Link></li>
        </ul>
      </div>
    </div>
  )
}

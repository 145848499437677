import React from 'react'
import ProductListingCards from '../../../components/Front/product_listing/product_cards'
import NavPath from '../../../components/Front/layout/nav_path'

export default function ProductLising() {
  return (
    <div>
      <NavPath activeTab="Mens"/>
      <ProductListingCards/>
    </div>
  )
}

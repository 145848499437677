import React from 'react'
import './banner.scss'
import { ReadedIcon } from '../../../../icons/incons'
import { images } from '../../../../actions/customFn'

export default function AboutBanner() {
    return (
        <div className='about-banner'>
            <div className='section'>
                <div className='container'>
                    <div className='banner-section position-relative'>
                        <div className='banner-content'>
                            <button className='common_button_cls' type='button'>WHO WE ARE</button>
                            <h2 className='common_heading_cls'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
                            <ul className='lists_cls'>
                                <li><ReadedIcon /> Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                                <li><ReadedIcon /> Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                                <li><ReadedIcon /> Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                                <li><ReadedIcon /> Lorem Ipsum is simply dummy text.</li>
                            </ul>
                        </div>
                        <div className='hero-img'>
                            <img src={images["goggle.png"]}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

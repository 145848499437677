import React from 'react'
import './products.scss'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { images } from '../../../../actions/customFn';
import { Heart } from '../../../../icons/incons';
import { Link } from 'react-router-dom';

const productSlider = {
    margin: 0,
    nav: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
        0: {
            items: 2,
        },
        375: {
            items: 3,
        },
        525: {
            items: 4,
        },
        786: {
            items: 4,
        },
        991: {
            items: 4.3,
        },
        1920: { 
            items: 4,
        },
    },
};
const ProductsCards = [
    {
        lable: 'New',
        img: 'specks1.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: 'New',
        img: 'specks2.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: '',
        img: 'specks3.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: '',
        img: 'specks4.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: '',
        img: 'specks1.png',
        details: 'Vincent case Polarized'
    },
    {
        lable: 'New',
        img: 'specks2.png',
        details: 'Vincent case Polarized'
    },
]
export default function Products() {
    return (
        <div className='section'>
            <div className='explore-products' style={{ background: "#EAEEEF" }}>
                <div className="product">
                    <div className='container'>
                        <div>
                            <p className='text_golden_cls'>Our Product</p>
                            <h2 className='common_heading_cls'>Explore our product</h2>
                        </div>
                        <div className='slide-items'>
                            <OwlCarousel
                                {...productSlider}
                                className="owl-theme"
                                margin={15}
                                key={`carousel_${Date.now()}`}>
                                {ProductsCards.map((item, index) => (
                                    <Link to="/products" key={index} >
                                        <div className='product-cards shine' >
                                            <div className='card-upper'>
                                                {item.lable &&
                                                    <div className='label'>
                                                        <span>New</span>
                                                    </div>
                                                }
                                                <button className='circle-btn'>
                                                    <Heart />
                                                </button>
                                                <img src={images[`${item.img}`]} alt="User" />
                                            </div>
                                            <div className='card-lower'>
                                                <p>{item.details}</p>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

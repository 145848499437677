import React from 'react'
import { images } from '../../../actions/customFn';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './empty_whishlist.scss'
import { ArrowBack } from '@mui/icons-material';
import NavPath from '../../../components/Front/layout/nav_path';
const EmptyWhishlist = React.memo(() => {
  return (
    <div>
        <NavPath activeTab="Wishlist"/>
      <div className='whishlist'>
        <div className='container'>
          <div className='center-box'>
            <div className='card_img'>
            <LazyLoadImage
              alt={"feature-image"}
              src={images["whishlist.png"]}
              effect="opacity"
              wrapperProps={{ style: { transitionDelay: '.1s' } }}
            /></div>
            <h2>Your Wishlist is empty.</h2>
            <p>You don’t have any products in the wishlist yet. You will find a lot
            of interesting products on our Shop page.</p>
            <div className='action-btn'>
                <button type='button' className='square-btn btn1'>Continue Shopping</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EmptyWhishlist;

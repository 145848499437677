import React from 'react'
import NavPath from '../../../components/Front/layout/nav_path'
import ContactBanner from '../../../components/Front/contact_us/banner'
import ContactAssist from '../../../components/Front/contact_us/assist'
import ContactApps from '../../../components/Front/contact_us/contactapps'

export default function ContactUs() {
  return (
    <div>
      <NavPath activeTab="Contact Us"/>
      <ContactBanner/>
      <ContactAssist/>
      <ContactApps/>
    </div>
  )
}

import React from 'react'
import './look_better.scss'
import { images } from '../../../../actions/customFn'

export default function AboutLookBetter() {
    return (
        <div className='look-better'>
            <div className='look-banner bg_image_cls' style={{ backgroundImage: `url(${images["about_look-bg.png"]})` }}>
                <div className='container'>
                    <div className='content'>
                    <h2 className='section_heading'>We made your everyday
                        Look better!</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the unknown printer took a galley.</p>
                        </div>
                </div>
            </div>
        </div>
    )
}

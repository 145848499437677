import React from 'react'
import { images } from '../../../actions/customFn';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './order_confirmed.scss'
const OrderConfirmed = React.memo(() => {
  return (
    <div>
      <div className='confirm-order'>
        <div className='container'>
          <div className='center-img'>
            <LazyLoadImage
              alt={"feature-image"}
              src={images["order-confirmed.png"]}
              effect="opacity"
              className="card_1imags"
              wrapperProps={{ style: { transitionDelay: '.1s' } }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default OrderConfirmed;

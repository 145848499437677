import React from 'react';
import NavPath from '../../../components/Front/layout/nav_path';
import BlogDetailData from '../../../components/Front/blog_detail';

const BlogDetial = React.memo(() => {
  return (
    <div>
       <NavPath activeTab="Blog Details"/>
      <BlogDetailData/>
    </div>
  );
});

export default BlogDetial;

import React from 'react'
import './gallery.scss'
import { images } from '../../../../actions/customFn'
import { Link } from 'react-router-dom'

const galleryItems = [
    {
        glassType: "Eye Glasses",
        glassWith: "With Power",
        cardData: [
            {
                img: 'man1.png',
                name: 'Men'
            },
            {
                img: 'women1.png',
                name: 'Women'
            },
            {
                img: 'kid1.png',
                name: 'Kids'
            }
        ]
    },
    {
        glassType: "Sun Glasses",
        glassWith: "With UV Protection",
        cardData: [
            {
                img: 'man2.png',
                name: 'Men'
            },
            {
                img: 'women2.png',
                name: 'Women'
            },
            {
                img: 'kid2.png',
                name: 'Kids'
            }
        ]
    },
    {
        glassType: "Blue Screen",
        glassWith: "With Power",
        cardData: [
            {
                img: 'man3.png',
                name: 'Men'
            },
            {
                img: 'women3.png',
                name: 'Women'
            },
            {
                img: 'kid3.png',
                name: 'Kids'
            }
        ]
    }
]
export default function Gallery() {
    return (
        <div className='section'>
            <div className='gallery'>
                <div className='container'>
                    {
                        galleryItems.map((item, index) => (
                            <div className='row mb-4'>
                                <div className='col-md-2'>
                                    <Link to="">
                                        <div className='glass-type-box'>
                                            <div className='upper-text'>
                                                <div className='verticle-text'>
                                                    <h2 className='common_heading_cls'>{item.glassType}</h2>
                                                </div>
                                            </div>
                                            <p className='text_golden_cls'>{item.glassWith}</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className='col-md-10 img-card'>
                                    <div className='grid-section'>
                                        {item.cardData.map((data) => (
                                                <Link to="/products">
                                                    <div className='gallery-card shine'>
                                                        <div className='gallery-img '>
                                                            <img src={images[`${data.img}`]} alt='dummy' />
                                                        </div>
                                                        <div className='photo-name'>
                                                            <p>{data.name}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

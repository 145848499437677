import React from "react"
import './arrivalProducts.scss'
import { images } from "../../../../actions/customFn"
import { Link } from "react-router-dom"


export default function ArrivalProducts() {
    return (
        <div className='section'>
            <div className='arrival'>
                <div className='container'>
                    <div>
                        <p className='text_golden_cls'>Featured</p>
                        <h2 className='common_heading_cls'>Our New Arrival Product</h2>
                    </div>
                    <div>
                        <div class="grid-container">
                            <div class="img img1">
                                <div className="arrival-cards bg_image_cls" style={{ backgroundImage: `url(${images["banner-1.png"]})` }}>
                                    <p className='text_golden_cls'>Featured</p>
                                    <div className="card-data card-flex">
                                        <h2 className="common_heading_cls">Eyecare at it’s best</h2>
                                        <Link className="common_button_cls" to="/products">Shop Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="img img2">
                                <div className="arrival-cards bg_image_cls" style={{ backgroundImage: `url(${images["banner-2.png"]})` }}>
                                    <p className='text_golden_cls'>Starting @₹250.00</p>
                                    <div className="card-data">
                                        <h2 className="common_heading_cls">Get the vision <br /> you deserve</h2>
                                        <Link className="common_button_cls" to="/products">Shop Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="last-grid">
                                <div class="img img3">
                                    <div className="arrival-cards bg_image_cls " style={{ backgroundImage: `url(${images["banner-3.svg"]})` }}>
                                        <p className='text_golden_cls'>Starting @₹250.00</p>
                                        <div className="card-data">
                                            <h2 className="common_heading_cls">Vision for life</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="img img4">
                                    <div className="arrival-cards bg_image_cls " style={{ backgroundImage: `url(${images["banner-4.svg"]})` }}>
                                        <p className='text_golden_cls'>Starting @₹250.00</p>
                                        <div className="card-data card-flex">
                                            <h2 className="common_heading_cls">For life long</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
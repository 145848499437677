import React, { useState } from 'react';
import './form.scss'
import { ArrowBack } from '@mui/icons-material';


const FaqForm = React.memo(() => {

    return (
        <div className='faq-form'>
            <div className='form_box'>
                <h2 className='text_18'>Don’t find your answer, Ask for support.</h2>
                <p className='text_14'>Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie accumsan dui, non iaculis primis in faucibu raesent eget sem purus.</p>
                <div className='theme_form'>
                    <div className='input-50'>
                    <input type='text' className='input_box' placeholder='Email address'/>  
                    <input  type='text' className='input_box' placeholder='Subject'/>
                    </div>
                    <textarea type='text' rows="4" className='input_box' placeholder='Message (Optional)'/>
                    <button type='button' className='square-btn'>Send message <ArrowBack /></button>
                </div>
            </div>
        </div>
    );
});

export default FaqForm;

import React from 'react'
import { images } from '../../../../actions/customFn'
import './assist.scss'
import { FileIcon2, ListIcon, LockIcon, Notes2, OwnerIcon, TruckIcon, UserIcon, UserYellowIcon } from '../../../../icons/incons'
import { Link } from 'react-router-dom'

const members = [
    {
        img: <TruckIcon/>,
        name: "Kevin Gilbert",
    },
    {
        img: <LockIcon/>,
        name: "Reset Password",
    },
    {
        img: <FileIcon2/>,
        name: "Payment Option",
    },
    {
        img: <UserYellowIcon/>  ,
        name: "User & Account",
    },
    {
        img: <ListIcon/>,
        name: "Wishlist",
    },
    {
        img: <Notes2/>,
        name: "Shipping & Billing",
    },
    {
        img: <FileIcon2/>,
        name: "Shopping Cart ",
    },
    {
        img: <OwnerIcon/>,
        name: "Owner",
    },
]

const ContactAssist = React.memo(() => {
    return (
        <div className='assist'>
            <div className='container'>
                <h2 className='section_heading'>What can we assist you with today?</h2>
                <div className='grid-section'>
                    {members.map((member, index) => (
                        <div className='horizontal-cards' key={index}>
                           <div>
                            {member.img}
                            </div>
                            <div>
                                <h3>{member.name}</h3>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='answer-section'>
                    <Link to="" className='square-btn'>CONTACT US</Link>
                    <h2 className='section_heading'>Don’t find your answer.<br/>
                    Contact with us</h2>
                </div>
            </div>
        </div>
    )
}
)
export default ContactAssist;
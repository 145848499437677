import React from 'react'
import AboutBanner from '../../../components/Front/about/banner'
import NavPath from '../../../components/Front/layout/nav_path'
import { AboutLookBetter, AboutSeller, Subscribe, Team } from '../../../components/Front'

export default function AboutUs() {
  return (
    <div>
      <NavPath activeTab="About Us"/>
      <AboutBanner/>
      <Team/>
      <AboutLookBetter/>
      <AboutSeller/>
      <Subscribe/>
    </div>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import './terms_condition.scss'
import NavPath from '../layout/nav_path'

export default function TermsContions() {

  return (
    <div>
      <NavPath activeTab="Terms and conditions"/>
    <div className='container'>
    <div className='terms_condition'>
        <h3>Privacy Policy</h3>
        <p>The Rental Hive handles personal information from users of This Site with respect and confidentiality and according to the law – and takes all reasonable steps to ensure that personal information is stored securely and accurately. Please contact us if you have any questions or comments about our policy, or our use of personal information.</p>
        <p>This document contains detailed information about cookies and how personal data are processed. This privacy policy may be revised from time to time as we add new services, or in response to legal changes or commercial developments. Privacy policy changes will be posted on This Site.</p>

        <h3>Personal information used by The Rental Hive</h3>
        <p >When registering, sending an enquiry or booking a holiday home through this site, personal information such as name, phone number and email address will be saved in our database because The Rental Hive needs to communicate important information such as booking confirmation, payment reminders and other booking related information.</p>

        <h3>Personal information use</h3>
        <p>Personal information stored by The Rental Hive will be used for the adequate function of this Site and the service provided, which includes, among others, the following purposes: bookings, enquiries, account administration, marketing activities, payments, sending communications, fraud detection and prevention, analytical purposes.</p>

        <h3>Personal data protection</h3>
        <p>The Rental Hive employs strict security measures to safeguard personal information and prevent disclosure to third parties without the user’s permission. For this purpose the data storage devices are kept in a secure facility with restricted physical access – and firewalls and other measures are used to restrict electronic access. Access to personally identifiable information is only given to internal staff of The Rental Hive requiring the information to perform a specific job. The Rental Hive will keep the user’s data safe thus cooperation with our security checks may be necessary before disclosing any information. Information will be retained for a reasonable period, or as long as legally required. To this extent, payments made through The Rental Hive are done via a third party secure certified payment provider and The Rental Hive does not store the full credit card details and is not liable for errors or losses on payments or money transfers.</p>
        <p>As part of This Site,The Rental Hive provides an internal account called Secure Inbox that allows direct written communication between the Guest and the Owner. For security purposes, The Rental Hive has an automated system that screens all communications for malicious or fraudulent content. That includes spam and the limitation of certain file types. Any sensitive information such as full credit card numbers that can be used for fraud may be masked automatically to ensure the safety of both Owners and Guests. The Rental Hive may also mask contact information which can be used to establish contact outside the Secure Inbox. In case of detecting a communication with malicious content, it may be blocked entirely. All communications sent and received using the Secure Inbox will be stored by The Rental Hive. To the extent permitted by law,The Rental Hive will only access internal communications between Owners and Guests if required by law, if strictly necessary for security or law enforcement purposes, or if The Rental Hive otherwise has a legitimate interest, such as to detect and prevent fraud and misuse. No communications will ever be passed on to a third party except if required by the law. The Rental Hive may analyse anonymized content of communications to improve its services.</p>
        <p>The personal data provided for the registration of the account as well as any other that could be provided during the booking procedure are included in a database called The Rental Hive duly registered with the Spanish Data Protection Agency, owned and processed by The Rental Hive online rentals SL. The acceptance of these terms and conditions implies the express consent to the referred data processing.</p>
        <p>For the exercise of the legal rights to access, rectification, cancellation or opposition to the data treatment a written 
          request by the data subject should be sent to the customer service department on following email
           <Link to="">support@therentalhive.com.</Link></p>

        <h3>Purpose of data processing</h3>
        <p>The personal data provided by users through the website are only used in accordance with the purpose for which the data has been collected.</p>
        <p>User account: The legal basis for the processing of this data is for the execution and provision of the corresponding services: Ad management and registration of your data to facilitate management and contact with the user. 
          This data is kept for the duration of the commercial relationship and for the time that is necessary to comply with any legal obligation.</p>
        <p>Contact form: Response to communications initiated by an interested party. The treatment of this data is legitimised by the consent from the interested party. In this case, the data is kept according to the purpose for which it has been collected for, and for a maximum of 2 years, unless the party concerned withdraws their consent before this.</p>
        <p>Subscription to our newsletter: Sending advertising about our products and services; information about promotions, distribution of activities and events organised by The Rental Hive. The treatment of this data is legitimised by the consent of the interested party. This data will be kept as long as the interested party does not request the cessation of activity.</p>
        <p>Sending of request: Sending and managing reservation requests to the owners or managers from the holiday homes advertised on The Rental Hive. The legal basis for this data collection is that it is required for the initial communication, necessary for future rental contracts or pre-contractual measures carried out by the interested party. This data is kept for as long as the commercial relationship continues and during the years necessary to comply with any legal obligations.</p>
        <p>Subscription to our promotion : Sending information of our promotions, distribution of activities and events organised by The Rental Hive. This data treatment is legitimised by the consent of the interested party and the data will be kept as long as the party interested does not request the cessation of the activity.</p>

        <h3>Recipients</h3>
        <p>No personal data will be transferred to third parties.</p>
        <p>Depending on the purpose in question, any personal data collected is categorised as a form of personal identification. This data can be in the form of your name, surname, nationality, identity document number or passport, as well as personal characteristics such as – date of birth, sex, contact information, telephone number, postal address and email address. Furthermore, this data can also relate to tourist properties – cadastral reference, address, tourist license number, and economic-banking data; all of this data will be treated with strict confidentiality, applying technical and organisational security measures, required by the current data protection regulations. The Rental Hive will not communicate this personal data to third parties, unless it is required to do so for legal or jurisdictional purposes or if the communication of this data is necessary in relation to the purpose of the treatment itself.</p>

        <h3 > Rights of the person in which the personal data belongs to </h3>
        <p>In regards to the treatment of personal data, the owner of this data can exercise their rights to access, rectify, request deletion, oppose, limit, or withdraw consent, among others options provided under the regulations of GDPR. This can be done by sending an email to the following address, support@therentalhive.com, or by writing to The Rental Hive and attaching a photocopy of a valid ID document along with what you request regarding the treatment of your personal data. If you do not agree with the treatment of your personal data, you can file a claim with the Spanish Agency for Data Protection AEPD (www.aepd.es).</p>

        <h3>Modification of the Privacy Policy and Treatment of Personal Data</h3>
        <p>The Rental Hive reserves the right to modify and/or update the content of this privacy policy and the processing of personal data at any time, either partially or in its entirety. Therefore, the user is advised to read the cookie policy carefully every time they access the website or periodically in order to be fully informed on the latest and most updated version of these policies.</p>

        <h3>Changes to company status</h3>
        <p>The Rental Hive reserves the right to disclose the collected information to any new Owner for the event of entering into an ownership agreement with another business. The Rental Hive will not otherwise disclose, sell, or distribute the information to any third party without permission – unless required to do so by law.</p>
        
        <h3>Use of Cookies</h3>
        <p>The Renal Hive uses cookies to allow the users of this site to be recognized across pages, and also allow the choices of the user to be remembered. Cookies are used as well to simplify the signing-in procedure, keep track of monitor usage.</p>

        <h3>Types of used cookies</h3>
        <p>The Rental Hive uses the following types of cookies:</p>
        <p><span>Technical cookies</span> : These are strictly necessary cookies for an adequate function of This Site.</p>
        <p><span>Performance cookies</span> : These cookies collect information about how visitors use This Site. These cookies do not collect information that can identify a user of This Site. All information these cookies collect is anonymous and is only used to improve the performance of This Site. The Rental Hive uses these cookies: to remember the visitor searches and accommodation viewed, to get statistics about how visitors use This Site, e.g. the number of visitors to a page, identify any errors that occur so that we improve This Site.</p>
      <p><span>Functionality cookies </span>: These cookies allow This Site to remember the choices visitors make, such as the visitor´s username in order to provide enhanced and personalized features.</p>
    </div>
    </div>
    </div>
  )
}

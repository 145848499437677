import React from 'react'
import './latest_blog.scss'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CalenderIcon, MessageIcon, UserIcon } from '../../../../icons/incons';
import { ArrowBack } from '@mui/icons-material';
import { images } from '../../../../actions/customFn';
import { Link } from 'react-router-dom';


export default function LatestBlog() {
    return (
        <div className=' latest_blog'>
            <div className='section'>
                <div className='container'>
                    <h2 className='section_heading'>Latest Blogs</h2>
                    <div className='grid-section'>
                        <Link to="/blog-detail">
                            <div className='border-box'>
                                <div className='card_img bg_image_cls' style={{ backgroundImage: `url(${images["blog1.png"]})` }}></div>
                                <div className='blog-card'>
                                    <div>
                                        <ul className='lists_cls'>
                                            <li className='text_14'><UserIcon />Cameron</li>
                                            <li className='text_14'><CalenderIcon />1 Feb, 2020</li>
                                            <li className='text_14'><MessageIcon />738</li>
                                        </ul>
                                        <p className='text_18'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took .</span>
                                        <button type='button' className='square-btn'>Read more<ArrowBack />  </button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/blog-detail">
                            <div className='border-box'>
                                <div className='card_img bg_image_cls' style={{ backgroundImage: `url(${images["blog2.png"]})` }}></div>
                                <div className='blog-card'>
                                    <div>
                                        <ul className='lists_cls'>
                                            <li className='text_14'><UserIcon />Cameron</li>
                                            <li className='text_14'><CalenderIcon />1 Feb, 2020</li>
                                            <li className='text_14'><MessageIcon />738</li>
                                        </ul>
                                        <p className='text_18'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took .</span>
                                        <button type='button' className='square-btn'>Read more<ArrowBack />  </button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/blog-detail">
                            <div className='border-box'>
                                <div className='card_img bg_image_cls' style={{ backgroundImage: `url(${images["blog3.png"]})` }}></div>
                                <div className='blog-card'>
                                    <div>
                                        <ul className='lists_cls'>
                                            <li className='text_14'><UserIcon />Cameron</li>
                                            <li className='text_14'><CalenderIcon />1 Feb, 2020</li>
                                            <li className='text_14'><MessageIcon />738</li>
                                        </ul>
                                        <p className='text_18'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took .</span>
                                        <button type='button' className='square-btn'>Read more<ArrowBack />  </button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import './top_menu.scss'
import { FacebookIcons, InstaIcon, LinkedInIcon, TwitterIcon } from '../../../../icons/incons'

export default function TopMenu() {
  return (
    <div className='top_menu'>
      <div className='container'>
        <div className='top-menu-item'>
          <div>
            <ul>
              <li><Link to="" className='text_small_cls'>Eyeglasses</Link></li>
              <li><Link to="" className='text_small_cls'>Sunglasses</Link></li>
              <li><Link to="" className='text_small_cls'>Computer glasses</Link></li>
              <li><Link to="" className='text_small_cls'>Reading Glasses</Link></li>
            </ul>
          </div>
          <div className='top-icons top-menu-item'>
            <ul>
              <li><Link to="" className='text_small_cls'>Lets Connect</Link></li>
              <li className='icons mr-0'>
                <Link to=""><FacebookIcons/></Link>
                <Link to=""><InstaIcon/></Link>
                <Link to=""><TwitterIcon/></Link>
                <Link to=""><LinkedInIcon/></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

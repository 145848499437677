import React from 'react'
import './contactapps.scss'
import { ArrowBack } from '@mui/icons-material';
import { images } from '../../../../actions/customFn';
import { Link } from 'react-router-dom';


const ContactApps = React.memo(() => {
  return (
    <div className='contact-app'>
      <div className='container'>
        <div className='grid-section'>
          <div className='border-box'>
            <div className='card_img'>
              <img src={images["phone.png"]} alt='phone' />
            </div>
            <div className='blog-card'>
              <p className='text_18'>Call us now</p>
              <p className='text_14'>we are available online from 9:00 AM to 5:00 PM (GMT95:45) <br />
                Talk with use now</p>
              <h3>+91-123-456-7894</h3>
              <Link to="" className='square-btn call'>Call now<ArrowBack />  </Link>
            </div>
          </div>
          <div className='border-box'>
            <div className='card_img'>
              <img src={images["whatsapp.png"]} alt='whatsap' />
            </div>
            <div className='blog-card'>
              <p className='text_18'>Chat with us</p>
              <p className='text_14'>we are available online from 9:00 AM to 5:00 PM (GMT95:45) <br />
                Talk with use now</p>
              <h3 >Support@zelariz.com</h3>
              <Link to="" className='square-btn whatsapp'>Contact Us<ArrowBack />  </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
)
export default ContactApps;
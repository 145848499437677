import TermsContions from "../components/Front/terms_conditions";
import { ContactUs, EmptyCart, EmptyWhishlist, Faq, Home, NotFound, OrderConfirmed, ProductDetail, ProductListing } from "../pages/Front";
import AboutUs from "../pages/Front/about";
import Blog from "../pages/Front/blog";
import BlogDetial from "../pages/Front/blog_detail";
const RouteArr = [
  // {
  //   name: "ClientDatabase",
  //   path: "/",
  //   key: "client-database",
  //   route: "/",
  //   element: <ClientDatabase />,
  // },
  {
    name: "Home",
    path: "/",
    key: "/",
    route: "/",
    element: <Home/>,
  },
  {
    name: "Products",
    path: "/products",
    key: "/",
    route: "/",
    element: <ProductListing/>,
  },
  {
    name: "Terms and Conditions",
    path: "/terms-conditions",
    key: "/",
    route: "/",
    element: <TermsContions/>,
  },
  {
    name: "About Us",
    path: "/about-us",
    key: "/",
    route: "/",
    element: <AboutUs/>,
  },
  {
    name: "Blog",
    path: "/blog",
    key: "/",
    route: "/",
    element: <Blog/>,
  },
  {
    name: "Blog Detail",
    path: "/blog-detail",
    key: "/",
    route: "/",
    element: <BlogDetial/>,
  },
  {
    name: "Order Confirmed",
    path: "/order-confirmed",
    key: "/",
    route: "/",
    element: <OrderConfirmed/>,
  },
  {
    name: "Empty Cart",
    path: "/empty-cart",
    key: "/",
    route: "/",
    element: <EmptyCart/>,
  },
  {
    name: "Page Not Found",
    path: "/not-found",
    key: "/",
    route: "/",
    element: <NotFound/>,
  },
  {
    name: "Empty Whishlist",
    path: "/empty-whishlist",
    key: "/",
    route: "/",
    element: <EmptyWhishlist/>,
  },
  {
    name: "Contact Us",
    path: "/contact-us",
    key: "/",
    route: "/",
    element: <ContactUs/>,
  },
  {
    name: "FAQ",
    path: "/faq",
    key: "/",
    route: "/",
    element: <Faq/>,
  },
  {
    name: "Product Details",
    path: "/product-details",
    key: "/",
    route: "/",
    element: <ProductDetail/>,
  },
];

export default RouteArr;

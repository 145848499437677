
import React, { useState } from 'react'
import './header.scss'
import { images } from '../../../../actions/customFn'
import { Heart, MenuIcon, Search, ShoppingIcon } from '../../../../icons/incons'
import { Link } from 'react-router-dom'
import MobileMenu from './mobileMenu'
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css"

export default function Header() {
    const [showMenu, setShowMeu] = useState(false);
    const toggleMenu = () => {
        setShowMeu((prevState) => !prevState)
    }
    return (
        <div className='header'>
            <div className='container'>
                <div className='header-box'>
                    <div className='mobile-icons'>
                        <button type='button' onClick={toggleMenu}><MenuIcon /></button>
                        <button type='button' className='search-filled'><Search /></button>
                    </div>

                    <div>
                        <Link to="">
                        <img src={images["logo.svg"]} alt='logo' className='logo' />
                        </Link>
                    </div>
                    <div className='mobile-icons'>
                        <button type='button'><Heart /></button>
                        <button type='button'><ShoppingIcon /></button>
                    </div>
                    <div className='searchbox'>
                        <div className='search-icon'><Search /></div>
                        <input placeholder='Search' />
                    </div>
                    <div className='header-menu'>
                        <ul>
                            <li><Link to="" className='text_small_cls'>Track Order</Link></li>
                            <li><Link to="" className='text_small_cls'>Profile</Link></li>
                            <li><Link to="" className='text_small_cls'><Heart /> <span className='count'>(0)</span></Link></li>
                            <li className='mr-0'><Link to="" className='text_small_cls'><ShoppingIcon /> <span className='count'>(0)</span></Link></li>
                        </ul>

                    </div>
                </div>
                <Drawer
                    open={showMenu}
                    onClose={toggleMenu}
                    direction='left'
                    className='mobile-left'
                    style={{ backgroundColor: "#0D3B65",width:"200px" }}
                >
                    <MobileMenu />
                </Drawer>
            </div>
        </div>
    )
}

import React from 'react'
import { images } from '../../../actions/customFn';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './not_found.scss'
import { ArrowBack } from '@mui/icons-material';
import {  HomeIcon2 } from '../../../icons/incons';
import NavPath from '../../../components/Front/layout/nav_path';
const NotFound = React.memo(() => {
  return (
    <div>
        <NavPath activeTab=""/>
      <div className='not-found'>
        <div className='container'>
          <div className='center-img'>
            <LazyLoadImage
              alt={"feature-image"}
              src={images["not-found.png"]}
              effect="opacity"
              className="card_1imags"
              wrapperProps={{ style: { transitionDelay: '.1s' } }}
            />
            <h2>404, Page not founds</h2>
            <p>Something went wrong. It’s look that your requested could not be found. It’s look like the link is broken or the page is removed.</p>
            <div className='action-btn'>
                <button type='button' className='square-btn btn1'><ArrowBack/><span>Go Back</span></button>
                <button type='button' className='square-btn btn2'><HomeIcon2/><span>Go To home</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NotFound;

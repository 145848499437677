import React from 'react'
import { images } from '../../../../actions/customFn'
import './team.scss'

const members = [
  {
    img: "dp1.png",
    name: "Kevin Gilbert",
    designation: "Product Designer"
  },
  {
    img: "dp2.png",
    name: "Kevin Gilbert",
    designation: "Assistant of CEO"
  },
  {
    img: "dp3.png",
    name: "Kevin Gilbert",
    designation: "Head of Designer"
  },
  {
    img: "dp4.png",
    name: "Kevin Gilbert",
    designation: "UX Designer"
  },
  {
    img: "dp5.png",
    name: "Kevin Gilbert",
    designation: "Product Designer"
  },
  {
    img: "dp6.png",
    name: "Kevin Gilbert",
    designation: "Head of Development"
  },
  {
    img: "dp7.png",
    name: "Kevin Gilbert",
    designation: "Design Engineer"
  },
  {
    img: "dp8.png",
    name: "Kevin Gilbert",
    designation: "UI Designer"
  },
]

export default function Team() {
  return (
    <div className='teams'>
      <div className='container'>
        <h2 className='section_heading'>Our core team member</h2>
        <div className='grid-section'>
          {members.map((member, index) => (
            <div className='horizontal-cards' key={index}>
              <img src={images[`${member.img}`]} />
              <div>
                <h3>{member.name}</h3>
                <p className='text_14'>{member.designation}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

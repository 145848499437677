import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import './filter.scss'
import { ArrowDown } from '../../../../icons/incons';


const FilterItems = [
    {
        name: "Frame Material",
        options: [
            { name: 'TR 90' },
            { name: 'TR 90 + Metal' },
            { name: 'Metal' },
            { name: 'Metal' }
        ]
    },
    {
        name: "Price Range",
        options: [
            { name: 'Rs. 2000 –  Rs. 2999' },
            { name: 'Rs. 3000 –  Rs. 3999' },
            { name: 'Rs. 4000 –  Rs. 4999' },
        ]
    },
    {
        name: "Frame Size",
        options: [
            { name: 'Narrow (128mm – 133mm)' },
            { name: 'Medium (134mm – 137mm)' },
            { name: 'Wide  (138mm - above)' },
        ]
    },
    {
        name: "Frame Shape",
        options: [
            { name: 'Hexagon' },
            { name: 'Oval' },
            { name: 'Pentagon' },
            { name: 'Cateye' },
            { name: 'Rectangle' },
            { name: 'Round' },
            { name: 'Square' },
            { name: 'Wayfarer' },

        ]
    },
    {
        name: "Gender",
        options: [
            { name: 'Men' },
            { name: 'Women' },
            { name: 'Unisex' },
        ]
    }
]
export default function ProductFilter() {
    const [activeKeys, setActiveKeys] = useState([]); // Tracks open accordion items

    const handleToggle = (key) => {
      setActiveKeys((prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Remove key if already active
          : [...prevKeys, key] // Add key if not active
      );
    };
    return (
        <>
            <div className='filter'>
                <h2 className='common_heading_cls'>FILTER</h2>
                <div className='filter-box'>
                    <Accordion defaultActiveKey={FilterItems.map((_, index) => `${index}`)} alwaysOpen>
                        {FilterItems.map((filter, index) => (
                            <Accordion.Item eventKey={`${index}`} key={index} className={`${index === 3 ? 'inline-filter' : ''}`}>
                                 <Accordion.Header
                                    onClick={() => handleToggle(`${index}`)} >
                                    {filter.name}
                                    <div
                                        className={`arrow-container ${activeKeys.includes(`${index}`) ? "open" : ""
                                            }`}>
                                        <ArrowDown />
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body >
                                    <div className="options-container">
                                        {filter.options.map((option, idx) => (
                                            <div class="form_check" key={idx}>
                                                <input className="form-check-input" type="checkbox" value="" id={`flexCheckDefault-${index}-${idx}`} />
                                                <label className="form-check-label text_small_cls" htmlFor={`flexCheckDefault-${index}-${idx}`}>
                                                    {option.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </Accordion.Body>
                                <hr className='hr' />
                            </Accordion.Item>
                        ))}
                    </Accordion>

                </div>
            </div>
        </>
    )
}

import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './questoins.scss'
import { MinusIcon, PlusIcon } from '../../../../icons/incons';


const questions = [
    {
        question: "Suspendisse ultrices pharetra libero sed interdum.",
        answer: "Nulla malesuada iaculis nisi, vitae sagittis lacus laoreet in. Morbi aliquet pulvinar orci non vulputate. Donec aliquet ullamcorper gravida. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie accumsan dui, non iaculis magna mattis id. Ut consectetur massa at viverra euismod. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent eget sem purus."
    },
    {
        question: "Quisque quis nunc quis urna tempor lobortis vel non orci. ",
        answer: "Nulla malesuada iaculis nisi, vitae sagittis lacus laoreet in. Morbi aliquet pulvinar orci non vulputate. Donec aliquet ullamcorper gravida. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie accumsan dui, non iaculis magna mattis id. Ut consectetur massa at viverra euismod. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent eget sem purus."

    },
    {
        question: "Donec rutrum ultrices ante nec malesuada. In accumsan eget nisi a rhoncus.",
        answer: "Nulla malesuada iaculis nisi, vitae sagittis lacus laoreet in. Morbi aliquet pulvinar orci non vulputate. Donec aliquet ullamcorper gravida. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie accumsan dui, non iaculis magna mattis id. Ut consectetur massa at viverra euismod. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent eget sem purus."

    },
    {
        question: "Nulla sed sapien maximus, faucibus massa vitae.",
        answer: "Nulla malesuada iaculis nisi, vitae sagittis lacus laoreet in. Morbi aliquet pulvinar orci non vulputate. Donec aliquet ullamcorper gravida. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie accumsan dui, non iaculis magna mattis id. Ut consectetur massa at viverra euismod. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent eget sem purus."

    },
]
const Questions = React.memo(() => {

    const [activeKey, setActiveKey] = useState([]);

    const handleToggle = (key) => {
        setActiveKey((prevKey) => (prevKey === key ? null : key));
    };
    return (
        <div className='question'>
            <h2 className='section_heading'>Frequently Asked Questions </h2>
            <Accordion  >
                {questions.map((item, index) => (
                    <Accordion.Item eventKey={`${index}`} key={index} className={activeKey === `${index}` ? "active" : ""}
                        onClick={() => handleToggle(`${index}`)} >
                        <Accordion.Header>
                            {item.question}
                            <div className='icons'>
                                {/* {activeKey.includes(`${index}`) ? <MinusIcon /> : <PlusIcon />} */}
                                <span className='icon_open icon_faq'>  <PlusIcon /> </span>
                                <span className='icon_close icon_faq'>  <MinusIcon /> </span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='text_14'>
                            {item.answer}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
});

export default Questions;

import React from 'react'
import { CalenderIcon, QuotesIcon } from '../../../icons/incons';
import { images } from '../../../actions/customFn';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './blog_details.scss'
const BlogDetailData = () => {
    return (
        <>
                <div className='container'>
                    <div className='blog-details'>
                        <div className='banner bg_image_cls' style={{ backgroundImage: `url(${images["summer.svg"]})` }}></div>
                        <div className='details'>
                            <p>By : <span>Admin</span></p>
                            <p><span className='calender'><CalenderIcon /></span> 09-10-2024</p>
                        </div>
                        <div className='content'>
                            <h2 className='public_32'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h2>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software </p>
                            <div className='quote'>
                                <div><QuotesIcon /></div>
                                <span>Vintage meets vogue is the only way to describe this serif typeface. Neue World encompasses the mode high-fashion aesthetic of the 1960s with a commercial take.</span>
                            </div>
                            <p>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
                            </p>
                            <p>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
                            </p>
                            <div className='blog_img'>
                                <LazyLoadImage
                                    alt={"feature-image"}
                                    src={images["blog2.png"]}
                                    effect="opacity"
                                    className="card_1imags"
                                    wrapperProps={{ style: { transitionDelay: '.1s' } }}
                                />
                                <LazyLoadImage
                                    alt={"feature-image"}
                                    src={images["blog3.png"]}
                                    effect="opacity"
                                    className="card_1imags"
                                    wrapperProps={{ style: { transitionDelay: '.1s' } }}
                                />
                            </div>
                            <p>
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,tially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software 
                            </p>
                        </div>
                    </div>
                </div>
        </>
    )
};
export default React.memo(BlogDetailData)
import React from 'react';
import News from '../../../components/Front/blog/news';
import NavPath from '../../../components/Front/layout/nav_path';
import LatestBlog from '../../../components/Front/blog/latest_blog';

const Blog = React.memo(() => {
  return (
    <div>
       <NavPath activeTab="Our Blog"/>
        <News/>
        <LatestBlog/>
    </div>
  );
});

export default Blog;

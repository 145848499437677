import React from 'react';
import { images } from '../../../../actions/customFn';
import './banner.scss'
import { Link } from 'react-router-dom';
const ContactBanner = React.memo(() => {
  return (
    <div className='contact-us'>
      <div className='hero-section'>
        <img src={images["girl-with-pc-bg.png"]} alt='girl-with-pc' className='img'/>
        <div className='content'>
          <div className='container'>
            <Link to="" className='square-btn'>Contact Us</Link>
            <h2 className='text_42'>How we can help you!</h2>
            <p className='text_14'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContactBanner;

import React, { useState, useEffect, useRef } from 'react';
import './details.scss'
import { Circle1, Circle2, Circle3, Heart, StarIcon, WarningIcon } from '../../../../icons/incons';
import { images } from '../../../../actions/customFn';
// import Slider from "react-slick";



const ItemDetails = React.memo(() => {
  // const [nav1, setNav1] = useState(null);
  // const [nav2, setNav2] = useState(null);
  // let sliderRef1 = useRef(null);
  // let sliderRef2 = useRef(null);

  // useEffect(() => {
  //   setNav1(sliderRef1);
  //   setNav2(sliderRef2);
  // }, []);

  return (
    <div className='details'>
      <div className='container'>
        <div className='detail-box'>





          <div className='images'>
            <div className='view-img'>
              <img src={images["details_pic.png"]} alt='specks' />
            </div>
            <div className='grid-section'>
              <div className='item'><img src={images["sp1.png"]} className='all-side' /></div>
              <div className='item'><img src={images["side2.png"]} className='all-side' /></div>
              <div className='item'><img src={images["side3.png"]} className='all-side' /></div>
              <div className='item'><img src={images["side4.png"]} className='all-side' /></div>
              <div className='item'><img src={images["side5.png"]} className='all-side' /></div>
              <div className='item'><img src={images["side1.png"]} className='all-side' /></div>
            </div>
            </div>
            {/* <div className="slider-container">
              <h2>Slider Syncing (AsNavFor)</h2>
              <h4>First Slider</h4>
              <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                <div>
                  <h3>1</h3>
                </div>
                <div>
                  <h3>2</h3>
                </div>
                <div>
                  <h3>3</h3>
                </div>
                <div>
                  <h3>4</h3>
                </div>
                <div>
                  <h3>5</h3>
                </div>
                <div>
                  <h3>6</h3>
                </div>
              </Slider>
              <h4>Second Slider</h4>
              <Slider
                asNavFor={nav1}
                ref={slider => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                <div>
                  <h3>1</h3>
                </div>
                <div>
                  <h3>2</h3>
                </div>
                <div>
                  <h3>3</h3>
                </div>
                <div>
                  <h3>4</h3>
                </div>
                <div>
                  <h3>5</h3>
                </div>
                <div>
                  <h3>6</h3>
                </div>
              </Slider>
            </div>
          </div> */}
            <div className='item-details'>
              <div className='rating'>
                <div className='stars'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <p>4.7 Star Rating <span>(21,671 User feedback)</span></p>
                </div>
                <button type='button' className='transparent-btn'>
                  <Heart />Add to Wishlist
                </button>
              </div>
              <div className='about_this'>
                <h3 className='name'>Xelariz Signa </h3>
                <p>Xelariz Signa Unisex Eyeglasses Full Frame Square Medium 51 TR90 SM XJ2238</p>
                <span className='waranty'>6 Month Manufacture warranty <WarningIcon /></span>
                <p className='size'>Size : <span className='bulue-color'>Medium</span><WarningIcon /></p>
                <h4 className='price'>₹2000 <span>(Including GST )</span></h4>
                <span className='info'>Frame + Lens</span>
                <div className='color'>
                  <span>Color</span>
                  <div className='circle-btns'>
                    <button type='button' className='active'><Circle1 /></button>
                    <button type='button'><Circle2 /></button>
                    <button type='button'><Circle3 /></button>
                  </div>
                </div>
              </div>
              <div className='bottom-label'>
                <p> Get 50% off + Free lenses</p>
                <p><span>Use Code – XELARIZ50</span></p>
              </div>
              <button type='button' className='square-btn'>Buy Now <span>(select lens)</span></button>
            </div>
          </div>
        </div>
      </div>
      );
});

      export default ItemDetails;

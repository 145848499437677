import React from 'react'
import { images } from '../../../actions/customFn';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './empty_cart.scss'
import { GreaterIcon } from '../../../icons/incons';
const EmptyCart = React.memo(() => {
  return (
    <div>
      <div className='empty-card'>
        <div className='container'>
          <div className='shop'>
            <button type='button' className='transparent-btn'> <GreaterIcon/> 
            </button>
            <h2 className='common_heading_cls'>Shopping Cart</h2>
          </div>
          <div className='center-img'>
            <LazyLoadImage
              alt={"feature-image"}
              src={images["empty-cart.png"]}
              effect="opacity"
              wrapperProps={{ style: { transitionDelay: '.1s' } }}
            />
            <h2 >Your cart is empty and sad :(</h2>
            <p className='text_18'>Add something to make it happy!</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EmptyCart;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RouteArr from "../hooks/GetRoutes";
import FrontApp from "../pages/Front/FrontApp";
// import FrontApp from "../pages/Front/FrontApp";
// import VendorApp from "../pages/Vendor/VendorApp";
// import vendorRoutes from "../hooks/vendorRoutes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontApp />,
    children: RouteArr,
  },
  // {
  //   path: "/vendor",
  //   element: <VendorApp />,
  //   children: vendorRoutes,
  // },
]);
const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;

import React from 'react'
import './promise.scss'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ReturnIcon, StyleIcon ,EyePowerIcon,Choise} from '../../../../icons/incons';

const productSlider = {
    margin: 0,
    nav: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
        0:{
            items: 2.5,
        },
        525: {
            items: 4,
        },
        
        786: {
            items: 4,
        },
        991: {
            items: 4.3,
        },
        1920: {
            items: 5,
        },
    },
};
const PromiseCards = [
    {
        icons: <ReturnIcon/>,
        details: '14 day free return'
    },
    {
        icons:<StyleIcon/>,
        details: 'Latest Styles'
    },
    {
        icons: <EyePowerIcon/>,
        details: 'All Powers'
    },
    {
        icons: <Choise/>,
        details: 'Wide Choices'
    },
    {
        icons: <ReturnIcon/>,
        details: '14 day free return'
    },
    {
        icons:<StyleIcon/>,
        details: 'Latest Styles'
    }
]
export default function Promise() {
    return (
        <div className='section'>
            <div className='promise-section' >
                <div className='container'>
                    <div>
                        <p className='text_golden_cls'>Our Promise</p>
                        <h2 className='common_heading_cls'>Xelariz Promise</h2>
                    </div>
                    <div className='slide-items'>
                        <OwlCarousel
                            {...productSlider}
                            className="owl-theme"
                            margin={15}
                            key={`carousel_${Date.now()}`}>
                            {PromiseCards.map((item, index) => (
                                <div className='promise-card' key={index}>
                                    <div className='card-upper'>
                                       {item.icons}
                                    </div>
                                    <div className='card-lower'>
                                        <p>{item.details}</p>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

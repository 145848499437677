import React from "react"
import './bestPicks.scss'
import { images } from "../../../../actions/customFn"

const ProductCards = [
    {
        frame_name: 'TR-90 Frames',
        glassImg: 'glass1.png',
        with: 'With Glasses'
    },
    {
        frame_name: 'Metal Frames',
        glassImg: 'glass2.png',
        with: 'With Glasses'
    },
    {
        frame_name: 'Transparent Frames',
        glassImg: 'glass3.png',
        with: 'With Glasses'
    },
    {
        frame_name: 'Shades Frames',
        glassImg: 'glass4.png',
        with: 'With Glasses'
    }
]
export default function BestPicks() {
    return (
        <div className='section'>
            <div className='best-picks'>
                <div className='container'>
                    <div>
                        <p className='text_golden_cls'>Get Unique Look For You</p>
                        <h2 className='common_heading_cls'>Style with our Best Picks</h2>
                    </div>
                    <div>
                        <div className="grid-section">
                            {ProductCards.map((item, index) => (
                                <div className="" key={index}>
                                    <div className="bestpicks-card bg_image_cls" style={{ backgroundImage: `url(${images["picks-bg.png"]})` }}>
                                        <div className="card-section">
                                            <div className="web-logo">
                                                <div className="logo-text">
                                                    <img src={images["logotext.svg"]} alt="logo" />
                                                </div>
                                            </div>
                                            <div className="glass-details">
                                                <h2 className="common_heading_cls">{item.frame_name}</h2>
                                                <p>{item.with}</p>
                                                <img src={images[`${item.glassImg}`]} alt="logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
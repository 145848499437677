import React from 'react'
import { images } from '../../../../actions/customFn'
import './seller.scss'


const products = [
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "Product Designer"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "Assistant of CEO"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "Head of Designer"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "UX Designer"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "Product Designer"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "Head of Development"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "Design Engineer"
  },
  {
    img: "small_glass.png",
    name: "Kevin Gilbert",
    designation: "UI Designer"
  },
]
export default function AboutSeller() {
  return (
    <div className='seller'>
      <div className='container'>
        <h2 className='section_heading'>Best Seller</h2>
        <div className='grid-section'>
          {products.map((item, index) => (
            <div className='horizontal-cards' key={index}>
              <img src={images[`${item.img}`]} />
              <div>
                <p className='text_14'>Xelariz Signa Unisex Eyeglasses </p>
                <span className='text_golden_cls'>₹1000</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import NavPath from '../../../components/Front/layout/nav_path'
import { InfoTabs, ItemDetails, Products } from '../../../components/Front'
import './product_details.scss'

export default function ProductDetail() {
  return (
    <div className='product-details'>
      <NavPath activeTab="Xelariz Signa XJ2238"/>
      <ItemDetails/>
      <InfoTabs/>
      <div className='container products_slider'>
        <h2 className='common_heading_cls'>You May Also Like</h2>
      </div>
      <Products/>
    </div>
  )
}

import React from 'react';
import NavPath from '../../../components/Front/layout/nav_path';
import Questions from '../../../components/Front/faq/questions';
import FaqForm from '../../../components/Front/faq/form';
import './faq.scss'

const Faq = React.memo(() => {
  return (
    <div>
      <NavPath activeTab="FAQ" />
      <div className='container'>
        <div className='faq-box'>
          <Questions />
          <FaqForm/>
        </div>
      </div>
    </div>
  );
});

export default Faq;

import React, { useEffect } from "react";
import "../../assets/vendor/scss/style.scss";
import "../../assets/vendor/scss/common_class.scss";

import "../../assets/vendor/css/bootstrap.css";
import { GetRoutes } from "../../actions/customFn";
import { Route, Routes } from "react-router-dom";
import RouteArr from "../../hooks/GetRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "./home";
import Master from "../../components/Front/layout/Master";

function FrontApp() {
  // Top To Scroll
  const routePath = useLocation();
  const navigate = useNavigate();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return <Master />;
}

export default FrontApp;

import React from 'react'
import './bestProduct.scss'
import { Link } from 'react-router-dom';
import { images } from '../../../../actions/customFn';
import { Heart } from '../../../../icons/incons';

export default function Products() {
    return (
        <div className='section'>
            <div className="best-product">
                <div className='container'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                        <p className='text_golden_cls'>Best Product</p>
                        <h2 className='common_heading_cls'>Product in the lime light</h2>
                        </div>
                        <Link to="/products" className='view-link'>View All</Link>
                    </div>
                    <div className='best-items'>
                        <div className='grid-section'>
                            <div className=''>
                                <Link to="/products">
                                <div className='items shine'>
                                    <button className='circle-btn'>
                                        <Heart />
                                    </button>
                                    <img src={images["girl1.png"]} />
                                    <div className='item-data'>
                                        <p>Vincent case Polarized</p>
                                        <span>Jhanvi’s  Brand</span>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className=''>
                            <Link to="/products">
                                <div className='items shine'>
                                    <button className='circle-btn'>
                                        <Heart />
                                    </button>
                                    <img src={images["girl2.png"]} />
                                    <div className='item-data'>
                                        <p>Vincent case Polarized</p>
                                        <span>Jhanvi’s  Brand</span>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className=''>
                            <Link to="/products">
                                <div className='items shine'>
                                    <button className='circle-btn'>
                                        <Heart />
                                    </button>
                                    <img src={images["girl3.png"]} />
                                    <div className='item-data'>
                                        <p>Vincent case Polarized</p>
                                        <span>Jhanvi’s  Brand</span>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <div className=''>
                             <Link to="/products">
                                <div className='items shine'>
                                    <button className='circle-btn'>
                                        <Heart />
                                    </button>
                                    <img src={images["girl4.png"]} />
                                    <div className='item-data'>
                                        <p>Vincent case Polarized</p>
                                        <span>Jhanvi’s  Brand</span>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

import React from "react";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";

// import DashboardSidebar from "./DashboardSidebar";
import Header from "./header";
import Annoucement from "./annoucement";
import TopMenu from "./top_menu";
import Footer from "./footer";

const Master = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >     

        <div>
           <div className="">
              <Annoucement/>
           </div>
           <div>
             <Header/>
           </div>
           <div>
             <TopMenu/>
           </div>
        </div>
          <div>
            <Outlet />
          </div>
        <div>
          <Footer/>
        </div>
    </motion.div>
  );
};

export default Master;

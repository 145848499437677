import React from 'react'
import './reading.scss'
import { images } from '../../../../actions/customFn'
import { Link } from 'react-router-dom'
export default function Reading() {
    return (
        <div className='section'>
            <div className='readig-section'>
                <div className='main-img'>
                    <img src={images["reading-banner.png"]} className='img' />
                    <div className='main-content'>
                        <div className='container'>
                            <div className='reading-content'>
                                <h2 className='common_heading_cls'>Reading Glasses</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <h3><span>3 </span> for <span>₹999</span>  </h3>
                                <Link className='common_button_cls' to="/products">
                                    Buy Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='readig-section bg_image_cls' style={{ backgroundImage: `url(${images["reading-banner.png"]})` }}>
                <div className='container'>
                        <div className='reading-content'>
                            <h2 className='common_heading_cls'>Reading Glasses</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <h3><span>3 </span> for <span>₹999</span>  </h3>
                            <Link className='common_button_cls' to="/products">
                                Buy Now
                            </Link>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

import React, { useState } from 'react'
import './productListing.scss'
import ProductFilter from '../productFilter'
import { Circle1, Circle2, Circle3, FilterIcon, Heart, Search2 } from '../../../../icons/incons'
import { images } from '../../../../actions/customFn'
import { Star } from '@mui/icons-material'
import YellowBanner from '../../yellow_banner'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom'

const recommendation = [
  { label: 'Recommended', year: 1994 },
  { label: 'Whats New', year: 1972 },
  { label: 'Popularity', year: 2008 },
  { label: 'Price: Low to High', year: 1957 },
  { label: 'Price: High to Low', year: 1993 },
  { label: 'Best saving', year: 1994 },


  // Add more static options as needed
];
export default function ProductListingCards() {
  const [showMenu, setShowMeu] = useState(false);
  const toggleMenu = () => {
    setShowMeu((prevState) => !prevState)
  }
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className='product-view'>
      <div className='container'>
        <YellowBanner />
        <div>
          <div className='product-box'>
            <div className='item1'>
              <ProductFilter />
            </div>
            <div className='item2'>
              <div className='box-header'>
                <div className='search'>
                  <div className='  div1'>
                    <div className='search-box'>
                      <input className='theme-input' type='text' placeholder='Search for anything...' />
                      <button type='button' className='transparent-btn'>
                        <Search2 />
                      </button>
                    </div>
                    <div className='filter-icon'>
                      <button type='button' onClick={toggleMenu}>
                        <FilterIcon /></button>
                    </div>
                  </div>
                  <p className='result div2'>Showing 18 of 336 Results</p>

                </div>
                <div className='sort-by div3'>
                  <label className='sort-text'>Sort by:</label>
                  <FormControl fullWidth className='recom-select'>
                    <Select
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      displayEmpty // Allows showing the placeholder
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span className='placeholder'>Recommended</span>; // Placeholder style
                        }
                        return selected;
                      }}
                    >
                      {recommendation.map((option) => (
                        <MenuItem key={option.label} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* =======================product-cards======================== */}
              <div className='product-grids'>
                <div className='grid-section'>
                  <Link to="/product-details">
                    <div className='product-card'>
                      <div className='card-details'>
                        <button className='circle-btn'>
                          <Heart />
                        </button>
                        <div className='card-img'>
                          <img src={images["sp1.png"]} alt='specks' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div className='rating'>
                            <span>4.8 <Star />{" "}|{" "}(647)</span>
                          </div>
                          <div className='circle-btns'>
                            <button type='button'><Circle1 /></button>
                            <button type='button'><Circle2 /></button>
                            <button type='button'><Circle3 /></button>
                          </div>
                        </div>
                        <h3 className='common_heading_cls'>Xelariz Signa</h3>
                        <p className='size'>Size : <span>Medium</span></p>
                        <h3 className='price text_18'>₹234</h3>
                      </div>
                      <div className='bottom-label'>
                        <p> Get 50% off + Free lenses</p>
                        <p><span>Use Code – XELARIZ50</span></p>
                      </div>
                    </div>
                    </Link>
                  <Link to="/product-details">
                    <div className='product-card'>
                      <div className='card-details'>
                        <button className='circle-btn'>
                          <Heart />
                        </button>
                        <div className='card-img'>
                          <img src={images["sp2.png"]} alt='specks' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div className='rating'>
                            <span>4.8 <Star />{" "}|{" "}(647)</span>
                          </div>
                          <div className='circle-btns'>
                            <button type='button'><Circle1 /></button>
                            <button type='button'><Circle2 /></button>
                            <button type='button'><Circle3 /></button>
                          </div>
                        </div>
                        <h3 className='common_heading_cls'>Xelariz Signa</h3>
                        <p className='size'>Size : <span>Medium</span></p>
                        <h3 className='price text_18'>₹234</h3>
                      </div>
                      <div className='bottom-label'>
                        <p> Get 50% off + Free lenses</p>
                        <p><span>Use Code – XELARIZ50</span></p>
                      </div>
                    </div>
                    </Link>
                  <Link to="/product-details">
                    <div className='product-card'>
                      <div className='card-details'>
                        <button className='circle-btn'>
                          <Heart />
                        </button>
                        <div className='card-img'>
                          <img src={images["sp3.png"]} alt='specks' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div className='rating'>
                            <span>4.8 <Star />{" "}|{" "}(647)</span>
                          </div>
                          <div className='circle-btns'>
                            <button type='button'><Circle1 /></button>
                            <button type='button'><Circle2 /></button>
                            <button type='button'><Circle3 /></button>
                          </div>
                        </div>
                        <h3 className='common_heading_cls'>Xelariz Signa</h3>
                        <p className='size'>Size : <span>Medium</span></p>
                        <h3 className='price text_18'>₹234</h3>
                      </div>
                      <div className='bottom-label'>
                        <p> Get 50% off + Free lenses</p>
                        <p><span>Use Code – XELARIZ50</span></p>
                      </div>
                    </div>
                    </Link>
                </div>
              </div>
            </div>
          </div>
          <Drawer
            open={showMenu}
            onClose={toggleMenu}
            direction='left'
            className='mobile-left'
          >
            <ProductFilter />
          </Drawer>
        </div>


      </div>
    </div>
  )
}

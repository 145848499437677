import React, { Fragment } from 'react';
import './tabs.scss'
import { FileIcon2, TruckIcon } from '../../../../icons/incons';
import { images } from '../../../../actions/customFn';

const DeliveryDetails = React.memo(() => {
    return (
        <>
            <div className='delivert_tab'>
               <div className='item_data'>
                <ul className='list_items'>
                    <li>Pincode</li>
                    <li>Free shipping across India</li>
                    <li>Orders are processes within 1-2 business days.</li>
                    <li>Shipping time is 3-5 business days.</li>
                    <li>Delivery time may vary depending upon the shipping address and 
                    other factors like (Public holidays , extremeweather conditions , etc.)</li>
                    <li>For any issue or questions , please contact Gmail / +91 1234567891 .</li>
                </ul>
                <div className='grey_box'>
                    <ul >
                        <li><img src={images['truck.svg']}/>Free Shipping</li>
                        <li><img src={images['exchange.svg']}/>3 Day easy return & exchange</li>
                        <li><img src={images['cod.svg']}/>COD available</li>
                        <li><img src={images['head_phones.svg']}/>Customer support (Everyday 10am – 7pm)</li>
                        <li><img src={images['file.svg']}/>Secure payment method</li>
                        <li><img src={images['batch.svg']}/>Comes with a warranty</li>
                    </ul>
                </div>
               </div>
            </div>
        </>
    );
});

export default DeliveryDetails;

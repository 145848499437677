import React from 'react'
import TopMenu from '../top_menu'
import './header.scss'
import { Link } from 'react-router-dom'
import { images } from '../../../../actions/customFn'

export default function MobileMenu() {

    return (
        <>
            <div className='mobile-menu'>
                <div className='mobile-logo'>
                    <Link to="/">
                        <img src={images["logo.svg"]} />
                    </Link>
                </div>
                <ul>
                    <li>Track Order</li>
                    <li>Profile</li>
                </ul>
                <TopMenu />
            </div>
        </>
    )
}

import React from 'react'
import { images } from '../../../../actions/customFn';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CalenderIcon, MessageIcon, UserIcon } from '../../../../icons/incons';
import './news.scss'
import { ArrowBack } from '@mui/icons-material';

import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';


const productSlider = {
  margin: 0,
  nav: false,
  dots: false,
  autoplay: false,
  autoplaySpeed: 3000,
  responsive: {
    0: {
      items: 1, // Display 1 item for screens 0px and above
    },
    525: {
      items: 2, // Display 1 item for screens 525px and above
    },
    769: {
      items: 3, // Display 2 items for screens 768px and above
    },
    1024: {
      items: 2, // Display 3 items for screens 1024px and above
    },
    1920: {
      items: 2, // Display 4 items for screens 1920px and above
    },
  }
};
const News = () => {
  return (
    <div className='page_section'>
      <div className='container'>
        <h2 className='section_heading'>Latest News</h2>
          <div className=''>
            <OwlCarousel
              {...productSlider}
              className="owl-theme"
              margin={15}
              key={`carousel_${Date.now()}`}>
             <Link to="/blog-detail">
              <div className='border-box'>
                <div className='card_img bg_image_cls' style={{ backgroundImage: `url(${images["goggle.png"]})` }}></div>
                <div className='blog-card'>
                  <div>
                    <ul className='lists_cls'>
                      <li className='text_14'><UserIcon />Cameron</li>
                      <li className='text_14'><CalenderIcon />1 Feb, 2020</li>
                      <li className='text_14'><MessageIcon />738</li>
                    </ul>
                    <p className='text_18'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</span>
                    <button type='button' className='square-btn'>Read more<ArrowBack />  </button>
                  </div>
                </div>
              </div>
              </Link>  
             <Link to="/blog-detail">
              <div className='border-box'>
              <div className='card_img bg_image_cls' style={{ backgroundImage: `url(${images["goggle2.png"]})` }}></div>
                <div className='blog-card'>
                  <div>
                    <ul className='lists_cls'>
                      <li className='text_14'><UserIcon />Cameron</li>
                      <li className='text_14'><CalenderIcon />1 Feb, 2020</li>
                      <li className='text_14'><MessageIcon />738</li>
                    </ul>
                    <p className='text_18'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</span>
                    <button type='button' className='square-btn'>Read more<ArrowBack />  </button>
                  </div>
                </div>
              </div>
              </Link>
              <Link to="/blog-detail">
              <div className='border-box'>
              <div className='card_img bg_image_cls' style={{ backgroundImage: `url(${images["blog1.png"]})` }}></div>
                <div className='blog-card'>
                  <div>
                    <ul className='lists_cls'>
                      <li className='text_14'><UserIcon />Cameron</li>
                      <li className='text_14'><CalenderIcon />1 Feb, 2020</li>
                      <li className='text_14'><MessageIcon />738</li>
                    </ul>
                    <p className='text_18'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</span>
                    <button type='button' className='square-btn'>Read more<ArrowBack />  </button>
                  </div>
                </div>
              </div>
              </Link>
            </OwlCarousel>
          </div>
      </div>
    </div>
  );
}

export default React.memo(News);
import React from 'react'
import { ArrivalProducts, Banner, BestPicks, BestProduct, Gallery, LookBetter, Products, Promise, Reading } from '../../../components/Front'
import Seller from '../../../components/Front/Home/products/seller'


const Home = () => {
  return (
    <div>
      <Banner />
      <Gallery />
      <Reading />
      <Products title="Our Product" heading="Explore our product" addclass="product"  />
      <BestPicks />
      <ArrivalProducts />
      <Seller  />
      <LookBetter />
      <BestProduct />
      <Promise />
    </div>
  )
}

export default Home

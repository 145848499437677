import React from 'react'
import './yellow_banner.scss'
import { images } from '../../../actions/customFn'
export default function YellowBanner() {
  return (
    <>
      <div className='page-banner'>
        <div className='banner-card'>
          <img src={images["yellow-banner.png"]} className='img' />
          <div className='main-content'>
            <div className='head-content'>
              <h3>Get 50% OfF On</h3>
              <h2 className='common_heading_cls'>Premium Men’s Eyewear Frames</h2>
              <button className='coupon-btn transparent-btn'><span>Coupon : WelcomeXE</span></button>
              </div>
          </div>
        </div>
      </div>
      {/* <div className='page-banner bg_image_cls' style={{ backgroundImage: `url(${images["product-bg.png"]})` }}>
        <div className='head-content'>
          <h3>Get 50% OfF On</h3>
          <h2 className='common_heading_cls'>Premium Men’s Eyewear Frames</h2>
          <button className='coupon-btn transparent-btn'><span>Coupon : WelcomeXE</span></button>
        </div>
        <img src={images["two-man.png"]} alt='mens' className='left-img' />
      </div> */}
    </>
  )
}

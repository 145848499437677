import { Link } from 'react-router-dom'
import { images } from '../../../../actions/customFn'
import './banner.scss'
import React from 'react'


function Banner() {
  return (
    <div className='section mt-0'>
      <div className='banner-img'>
        <div className='main-img'>
          <img src={images["home-banner.png"]} className='img' alt="" />
          <div className='main-content'>
            <div className='container'>
              <div className='hero-text'>
                <h1>Perfect Glasses<br />For Your Unique Style</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.
                </p>
                <Link to="/products" className='common_button_cls'>
                  Shop Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <Link to="/products" style={{background: `linear-gradient(${color1} 50%, ${color2} 50%, ${color2} 50%, ${color3} 50%)`}} className='common_button_cls'>
                  Shop Now
               </Link> */}
        {/* 
      <div className='banner-img bg_image_cls' style={{ backgroundImage: `url(${images["home-banner.png"]})` }}>
        <div className='container'>
          <div className='hero-text'>
            <h1>Perfect Glasses<br />For Your Unique Style</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            </p>
            <Link to="/products" className='common_button_cls'>
              Shop Now
            </Link>
          </div>
        </div> */}

        {/* <div className='container'>
          <div className='banner-data'>
            <div className='content-50'>
              <div className='hero-text'>
                <h1>Perfect Glasses<br/>For Your Unique Style</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <Link to="/products" className='common_button_cls'>
                  Shop Now
                </Link>
              </div>
            </div>
            <div className='hero-img'>
              <img src={images["hero-img.png"]} className='img' />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Banner

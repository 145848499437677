import './annoucement.scss'
import React from 'react'

export default function Annoucement() {
  return (
    <div>
      <section>
        <div className='annoucement-bg   new-class'>
          <p><span>Flat 50%Off </span> on All Product Use <span> Code: XEL50</span></p>
        </div>
      </section>
    </div>
  )
}
